import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import FeatureCardsSection from '../components/featureCardsSection';
import KafkaIcon from '../../assets/svg/kafka-plain.svg';
import PostgresIcon from '../../assets/svg/postgresql-plain.svg';
import MongoIcon from '../../assets/svg/mongodb-plain.svg';
import NestIcon from '../../assets/svg/nestjs-plain.svg';
import RedisIcon from '../../assets/svg/redis-plain.svg';
import GolangIcon from '../../assets/svg/golang.svg';
import TechBox from '../components/techBox';

import MicroservicesIcon from '../../assets/svg/microservices.svg';
import CloudIcon from '../../assets/svg/cloud.svg';
import RobotIcon from '../../assets/svg/robot.svg';
import StopwatchIcon from '../../assets/svg/stopwatch.svg';
import SEO from '../components/seo';

const BackendPage = () => {
  const data = useStaticQuery(graphql`
        query BackendImages {
            aplikacjeSerwerowe: file(
                relativePath: {
                    eq: "aplikacje-serwerowe-l.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaSRV: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Backend
          {' '}
          <span>Apps</span>
        </>
)}
      subtitle="Performant and scalable server-side applications"
      img="/img/biuro-komputery-programowanie.jpg"
    >
      <SEO title="Node.js and microservices backend development - iqcode Software House" description="We are a software house based in Warsaw, Poland which builds modern server-side applications using Node.js, Python and microservices architecture." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              <span>Backend</span>
              {' '}
              Development
            </>
                      )}
          img={<Img alt="backend applications" fluid={data.aplikacjeSerwerowe.childImageSharp.fluid} />}
          desc={(
            <>
              <p>
                Virtually all PWAs, mobile apps and web apps require an appropriate backend application, which is a server-side program responsible for data mutations, user authentication, data analytics and administrative functionalities.
              </p>
              <p>
                We design and build performant and scalable server-side applications deployed in environments that guarantee high availability and data security. Our wide range of programming languages and frameworks allows us to ship projects ranging from simple MVPs all the way up to complex distributed systems in microservices architecture, machine learning solutons and real-time data processing projects.
              </p>
            </>
                      )}
          bottom={(
            <TechBox
              fullWidth
              technologies={[
                {
                  icon: <MicroservicesIcon />,
                  name: 'Microservices Architecture',
                },
                {
                  icon: <RobotIcon />,
                  name: 'Machine Learning',
                },
                {
                  icon: <CloudIcon />,
                  name: (<>
                    Cloud-Native
                    <br />
                    Apps
                  </>),
                },
                {
                  icon: <StopwatchIcon />,
                  name: 'Rapid Prototyping',
                },
              ]}
            />
)}
        />

      </div>
      <div className="section" id="aplikacje-mobilne">
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Let's estimate your project
              {' '}
              <span>Project</span>
              !
            </h3>
            <Link className="btn" to="/contact/">Get in touch</Link>
          </div>
        </div>
        <FeatureCardsSection
          id="backend"
          title={(
            <>
              <span>Backend</span>
              {' '}
              Technologies
            </>
                  )}
          subtitle="Our backend development tech stack"
          cards={[
            {
              title: 'Node.js',
              desc:
                            'Server-side JavaScript runtime environment which allows for creation of performant, I/O intensive backend applications.',
              icon: <NodeIcon />,
            },
            {
              title: 'NestJS',
              desc:
                            'Progressive Node.js backend application framework that integrates neatly with TypeScript.',
              icon: <NestIcon />,
            },
            {
              title: 'Python',
              desc:
                            'The world\'s most popular programming language that we use for building web apps, data science and machine learning projects.',
              icon: <PythonIcon />,
            },
            {
              title: 'Golang',
              desc:
                            'A modern programming language that we use for creating performance-focused backend applications as well as plugins for DevOps tools.',
              icon: <GolangIcon />,
            },
            {
              title: 'Redis',
              desc:
                            'In-memory database most commonly used as backend application read/write cache as well as a rate limiting store.',
              icon: <RedisIcon />,
            },
            {
              title: 'PostgreSQL',
              desc:
                            'Open-source relational database offering high availability and scalability thanks to its replication functionality.',
              icon: <PostgresIcon />,
            },
            {
              title: 'MongoDB',
              desc:
                            'Modern NoSQL database capable of storing enormous amounts of conveniently structured data leveraging the document-oriented data model.',
              icon: <MongoIcon />,
            },
            {
              title: 'Apache Kafka',
              desc:
                            'Distributed stream processing platform that we use as a means of asynchronous communication between microservices.',
              icon: <KafkaIcon />,
            },
          ]}
        />
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Seeking IT project
              {' '}
              <span>consulting</span>
              ?
            </h3>
            <Link className="btn" to="/consulting-it/">Let us help</Link>
          </div>
        </div>
        <FeatureSectionLight
          title={(
            <>
              <span>All-rounded </span>
              IT project delivery
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="complex software development services"
                fluid={data.kompleksowaSRV.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Backend development is not the only area of our expertise. We deliver complex IT projects starting starting at the requirements engineering stage, through development works and project consulting regarding the app's public start finishing off with rock-solid tech support, data-driven analytics and iterative evolution of the digital product.
              </p>
              <p>
                Our wide variety of mobile, web and server-side technologies allows us to deliver complex IT projects cross-cutting numerous software engineering areas.
              </p>
            </>
                  )}
          link="Get in touch"
          to="/contact"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default BackendPage;
